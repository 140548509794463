import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { CompanyReportsService } from '../../../company-reports/services/company-reports.service';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ApiSettings, featureFlagSettings, EstimatesSettings } from '../../../settings.class';
import { EstimatesService } from '../../services/estimates.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExcelImportModalComponent } from '../excel-import-modal/excel-import-modal.component';
import { Subject, combineLatest, of } from 'rxjs';
import { FeatureFlagService } from 'app/shared/services/feature-flag.service';
import { EntitiesService, EntityDescription } from 'app/entities/services/entities.service';
import { sentenceCase, snakeCase } from 'change-case';
import { ValidatorConversionService } from 'app/entities/services/validator-conversion.service';
import { CalendarService } from 'app/calendar/calendar.service';

@Component({
  selector: 'con-active-consensus',
  templateUrl: './active-consensus.component.html',
  styleUrls: ['./active-consensus.component.scss']
})
export class ActiveConsensusComponent implements OnInit, OnDestroy {
  company: any;
  showData = false;
  consensusForm: FormGroup;
  currentRouteParams: any;
  consensusData: any;
  latestSurveyId!: number;
  fetchConsensusLoading = false;
  currentSurveyDetails: any;
  isLoading = false;
  private componentDestroyed$ = new Subject();
  isAPISyncEnabled: boolean = false;
  featuresEnabled: any = {};
  featureFlaggedKeys = featureFlagSettings.FEATURE_KEYS;
  formFields: {  target_price: any, outlook: any };
  formErrors: { target_price: string[], outlook: string[] } = { target_price: [], outlook: [] };
  consensusFormLoading = { target_price: false, outlook: false }

  constructor( 
    private http: HttpClient, 
    private estimateService: EstimatesService, 
    private route: ActivatedRoute, 
    private companyReportService: CompanyReportsService, 
    private fb: FormBuilder, 
    private toaster: ToastrService, 
    private modalService: NgbModal, 
    private entityService: EntitiesService,
    private featureFlagService: FeatureFlagService,
    private validatorService: ValidatorConversionService,
    private calendarService: CalendarService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.pipe(
      takeUntil(this.componentDestroyed$),
      switchMap((params: Params) => {
        this.currentRouteParams = params;
        if (params && params.id && params.companyId) {
          return combineLatest([
            this.estimateService.getConsensusValues().pipe(
              takeUntil(this.componentDestroyed$),
              switchMap((consensusData) => {
                if(consensusData)
                  return of(consensusData);
                else{
                  this.isLoading = true;
                  return this.estimateService.getConsenusData(params.id)
                }
              })
            ),
            this.companyReportService.getCompanyData(params.companyId),
            this.estimateService.getLatestSurveyDetails(params.companyId),
            this.estimateService.getAPISyncStatus(params.companyId),
            this.featureFlagService.isFeaturesEnabled([this.featureFlaggedKeys.estimates_manual_onboarding]),
            this.entityService.getEntityDescriptionByEntityName('ConsensusTargetPrice'), 
            this.entityService.getEntityDescriptionByEntityName('ConsensusOutlook'),
            this.estimateService.getCurrentSurveyApproveStatus().pipe(
              switchMap((data) => {
                this.isLoading = true;
                return this.estimateService.getSurvey(params.id);
              }),
              takeUntil(this.componentDestroyed$)
            )
          ]).pipe(
            map(([consenusData, companyDetails, latestSurveys, APISyncStatus, featuresEnabled, targetPriceEntity, outlookEntity, surveyData]) => ({ consenusData, companyDetails, latestSurveys, APISyncStatus, featuresEnabled, targetPriceEntity, outlookEntity, surveyData }))
          );
        } else {
          return of(null);
        }
      })
    ).subscribe(
      (data) => {
        if (data) {
          this.company = data?.companyDetails;
          this.showData = true;
          this.consensusData = data?.consenusData;
          this.currentSurveyDetails = data?.surveyData;
          this.latestSurveyId = data?.latestSurveys?.data[0]?.id;
          this.isAPISyncEnabled = data?.APISyncStatus;
          this.featuresEnabled = data?.featuresEnabled;
          this.formFields = {
            target_price: data?.targetPriceEntity?.getFieldsObject(),
            outlook: data?.outlookEntity?.getFieldsObject()
          }
          this.buildForm(this.consensusData);
          if (this.consensusData.estimates) {
            for (data of this.consensusData.estimates) {
              data.hide = true;
            }
          }
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  getConsenusData() {
    this.isLoading = true;
    this.http.get(ApiSettings.BASE_URL + `/estimates/survey/${this.currentRouteParams.id}/consensus`).subscribe((data: any) => {
      this.isLoading = false;
      this.showData = true;
      this.consensusData = data;
      this.buildForm(this.consensusData);
      if (this.consensusData.estimates) {
        for (data of this.consensusData.estimates) {
          data.hide = true;
        }
      }
    })
  }

  buildForm(data: any) {
    this.consensusForm = new FormGroup({
      target_price: this.createTargetPriceForm(data.target_price),
      outlook: this.createOutlookForm(data.outlook)
    });
    if(this.currentSurveyDetails.locked){
      this.consensusForm.disable();
    }
  }

  createTargetPriceForm(targetPriceData) {
    const validators: any = this.validatorService.getValidatorsForFields(this.formFields.target_price)
    return new FormGroup({
      mean: new FormControl({ value: this.estimateService.setFormValue(targetPriceData?.mean), disabled: this.formFields.target_price?.mean?.disabled }, validators.mean || []),
      median: new FormControl({ value: this.estimateService.setFormValue(targetPriceData?.median), disabled: this.formFields.target_price?.median?.disabled }, validators.median || []),
      high: new FormControl({ value: this.estimateService.setFormValue(targetPriceData?.high), disabled: this.formFields.target_price?.high?.disabled }, validators.high || []),
      low: new FormControl({ value: this.estimateService.setFormValue(targetPriceData?.low), disabled: this.formFields.target_price?.low?.disabled }, validators.low || []),
      amount: new FormControl({ value: this.estimateService.setFormValue(targetPriceData?.amount), disabled: this.formFields.target_price?.amount?.disabled }, validators.amount || []),
    });
  }

  createOutlookForm(outlookData) {
    const validators: any = this.validatorService.getValidatorsForFields(this.formFields.outlook);
    return new FormGroup({
      buy: new FormControl({ value: this.estimateService.setFormValue(outlookData?.buy), disabled: this.formFields.outlook?.buy?.disabled }, validators.buy || []),
      overweight: new FormControl({ value: this.estimateService.setFormValue(outlookData?.overweight), disabled: this.formFields.outlook?.overweight?.disabled }, validators.overweight || []),
      hold: new FormControl({ value: this.estimateService.setFormValue(outlookData?.hold), disabled: this.formFields.outlook?.hold?.disabled }, validators.hold || []),
      underweight: new FormControl({ value: this.estimateService.setFormValue(outlookData?.underweight), disabled: this.formFields.outlook?.underweight?.disabled }, validators.underweight || []),
      sell: new FormControl({ value: this.estimateService.setFormValue(outlookData?.sell), disabled: this.formFields.outlook?.sell?.disabled }, validators.sell || []),
    });
  }

  onSubmit(formType: string) {
    if( this.currentSurveyDetails?.locked) return;
    this.consensusForm.get(formType).markAllAsTouched();
    this.formErrors[formType] = [];
    if(!this.consensusData.id){
      this.formErrors[formType].push('This survey does not have any consensus. Please create a consensus first.');
      return;
    }
    if(this.consensusForm.controls[formType].invalid) return
    const payload = {
      ...this.consensusForm.get(formType).value,
      consensus_id: this.consensusData.id || undefined,
      id: this.consensusData[formType]?.id || undefined
    }
    this.consensusFormLoading[formType] = true;
    this.entityService.saveEntity(`consensus_${snakeCase(formType)}`, payload).pipe(takeUntil(this.componentDestroyed$)).subscribe((data) => {
      this.toaster.success(`${this.consensusData[formType]?.id ? 'Updated' : 'saved'} successfully!`, sentenceCase(`Consensus ${formType}`));
      this.consensusData[formType] = data;
      this.buildForm(this.consensusData);
      this.consensusFormLoading[formType] = false;
    }, (error) => {
      if(error.isValueError()) {
        this.formErrors[formType] = this.calendarService.getFlattendErrorArray(error.data);
      } else {
        this.toaster.error(ApiSettings.INTERNAL_SERVER_ERROR);
      }
      this.consensusFormLoading[formType] = false;
    });
  }

  getStripedWebsiteString(companyWebsite: string): string {
    if (companyWebsite) {
      return companyWebsite.replace(/(^\w+:|^)\/\//, '');
    }
    return '';
  }

  fetchLatestConsensusManually() {
    if( this.currentSurveyDetails?.locked || !this.isAPISyncEnabled )
      return;
    this.fetchConsensusLoading = true;
    this.estimateService.fetchLatestConsensus(this.currentRouteParams.id).pipe(takeUntil(this.componentDestroyed$)).subscribe((data: any) => {
      this.toaster.success('A process has been initiated to fetch latest consensus');
      this.fetchConsensusLoading = false;
    },
    err => {
      this.fetchConsensusLoading = false;
      if(err?.data && err?.data?.type === ApiSettings.RESPONSES.VALUE_ERROR)
        this.toaster.error(this.entityService.getFirstErrorForToaster(err?.data) || 'Failed to initiate a process to fetch latest consensus');
      else
        this.toaster.error('Failed to initiate a process to fetch latest consensus');
    })
  }

  downloadTemplate() {
    if(this.currentSurveyDetails?.locked)
      return;
    this.estimateService.downloadExcelTemplate(this.currentRouteParams.id).pipe(takeUntil(this.componentDestroyed$)).subscribe((response: any) => {
      const contentDisposition = response.headers.get('content-disposition');
      const downloadAnchor = document.createElement('a');
      downloadAnchor.href = window.URL.createObjectURL(new Blob([response.body], { type: 'application/xlsx' }));
      downloadAnchor.download = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');;
      downloadAnchor.click();
      this.toaster.success('Template downloaded successfully');
    },
      err => {
        this.toaster.error('Failed to download template');
      }
    );
  }

  openImportModal() {
    if(this.currentSurveyDetails?.locked)
      return;
    const modalRef = this.modalService.open(ExcelImportModalComponent, { size: 'md' });
    modalRef.componentInstance.surveyId = this.currentRouteParams.id;
    modalRef.componentInstance.quantity = this.consensusData?.quantity || null
    modalRef.result.then(() => {
      this.getConsenusData();
      this.refreshSyncStatus();
    }, () => { });
  }

  refreshSyncStatus(){
    this.estimateService.getAPISyncStatus(this.currentRouteParams.companyId).pipe(take(1)).subscribe((status: boolean) => {
      this.isAPISyncEnabled = status;
    })
  }
  
  updateFigureValue(eventDetails: any, period: any) {
    const { module: figureModule, field, value } = eventDetails;
    if (!figureModule || !field) return;
    const findModuleByLevel = (modules, lineId, level, parentId = null) => {
        if (level === 0) {
            return modules?.find(module => module.line_id === lineId);
        } else if (level === 1) {
            const parentModule = modules?.find(module => module.line_id === parentId);
            return parentModule?.children?.find(child => child.line_id === lineId);
        } else if (level === 2) {
            for (const module of modules) {
                const parentModule = module.children?.find(child => child.line_id === parentId);
                if (parentModule) {
                    return parentModule.children?.find(child => child.line_id === lineId);
                }
            }
        }
        return null;
    };
    const targetModule = findModuleByLevel( period.modules, figureModule.line_id, figureModule.level, figureModule.parentId );
    if (targetModule) {
        targetModule[field] = value;
    }
}

}
