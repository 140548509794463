<div>
  <table class="table bg-white tree-table mb-0">
    <thead class="thead-light">
      <tr>
        <th>Module Name</th>
        <th>Mean</th>
        <th>Median</th>
        <th>High</th>
        <th>Low</th>
        <th>Contr</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let module of modulesArray; let i = index">
        <tr *ngIf="shouldShowRow(module)">
          <td [ngClass]="levelClass(module)">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <button *ngIf="module?.hasChildren" class="btn btn-sm expand-btn"  (click)="toggleExpand(module); $event.stopPropagation()">
                  <con-icon-by-name [iconName]="module?.isExpanded ? 'angle-down' : 'angle-right'" ></con-icon-by-name>
                </button>
                <div class="expand-btn-placeholder" *ngIf="!module.hasChildren"></div>
                <span>
                  {{module?.alias || module?.name}}
                  <b class="ml-2" *ngIf="currency?.id && module?.currency?.code && module?.currency?.code !== currency?.code && !module?.formControls?.currency_id">
                    {{module?.currency?.code}} ({{module?.currency?.symbol}})
                  </b>
                </span>
              </div>
              <div class="d-flex align-items-center">
                <ng-container *ngIf="module?.formControls?.currency_id">
                  <div class="alert alert-sm alert-info alert-dismissible mb-0" *ngIf="module?.newCurrency">
                    <button type="button" class="close" aria-label="Close" (click)="clearCurrency(module)">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <strong>{{module?.newCurrency?.code}} ({{module?.newCurrency?.symbol}})</strong>
                  </div>
                  <ng-container *ngIf="!module?.newCurrency">
                    <con-entity-search-field entityName="Currency" [isSmallSizeInput]="true" (onSelect)="selectCurrency($event, module)"></con-entity-search-field>
                  </ng-container>
                  <button class="btn btn-sm btn-success mx-1" (click)="saveField(module, 'currency_id')" [disabled]="module?.loaders?.currency_id || module?.formControls?.currency_id?.pristine">
                    <con-icon-by-name iconName="check" *ngIf="!module?.loaders?.currency_id"></con-icon-by-name>
                    <div class="spinner-border spinner-border-sm" *ngIf="module?.loaders?.currency_id"></div>
                  </button>
                  <button class="btn btn-sm btn-secondary"  (click)="toggleEditMode(module, false, 'currency_id')"> <con-icon-by-name iconName="times"></con-icon-by-name></button>
                </ng-container>
                <ng-container *ngIf="!module?.formControls?.currency_id && !isSurveyLocked">
                  <button class="btn btn-sm ml-1 px-0 no-shadow" [ngbTooltip]="'Edit currency'" (click)="toggleEditMode(module, true, 'currency_id')"> <con-icon-by-name iconName="pencil"></con-icon-by-name> </button>
                </ng-container>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-between align-items-center">
              <ng-container *ngIf="module?.formControls?.mean">
                <input class="from-control form-control-sm" type="number" [formControl]="module?.formControls?.mean">
                <button class="btn btn-sm btn-success mx-1" (click)="saveField(module, 'mean')" [disabled]="module?.loaders?.mean || module?.formControls?.mean?.pristine">
                  <con-icon-by-name iconName="check" *ngIf="!module?.loaders?.mean"></con-icon-by-name>
                  <div class="spinner-border spinner-border-sm" *ngIf="module?.loaders?.mean"></div>
                </button>
                <button class="btn btn-sm btn-secondary"  (click)="toggleEditMode(module, false, 'mean')"> <con-icon-by-name iconName="times"></con-icon-by-name></button>
              </ng-container>
              <ng-container *ngIf="!module?.formControls?.mean">
                {{ !module?.mean ? '-' : module?.mean | imask: maskOptions }}
                <button class="btn btn-sm ml-1 px-0 no-shadow" *ngIf="!isSurveyLocked" (click)="toggleEditMode(module, true, 'mean')" [ngbTooltip]="'Edit mean'" >
                  <con-icon-by-name iconName="pencil"></con-icon-by-name>
                </button>
              </ng-container>
            </div>
            <div class="errors" *ngIf="module?.formControls.mean">
              <con-form-errors [control]="module?.formControls?.mean" [extra]="module?.formErrors?.mean"></con-form-errors>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-between align-items-center">
              <ng-container *ngIf="module?.formControls.median">
                <input class="from-control form-control-sm" type="number" [formControl]="module?.formControls?.median">
                <button class="btn btn-sm btn-success mx-1" (click)="saveField(module, 'median')" [disabled]="module?.loaders?.median || module?.formControls?.median?.pristine">
                  <con-icon-by-name iconName="check" *ngIf="!module?.loaders?.median"></con-icon-by-name>
                  <div class="spinner-border spinner-border-sm" *ngIf="module?.loaders?.median"></div>
                </button>
                <button class="btn btn-sm btn-secondary"  (click)="toggleEditMode(module, false, 'median')"> <con-icon-by-name iconName="times"></con-icon-by-name></button>
              </ng-container>
              <ng-container *ngIf="!module?.formControls?.median">
                {{ !module?.median ? '-' : module?.median | imask: maskOptions }}
                <button class="btn btn-sm ml-1 px-0 no-shadow" *ngIf="!isSurveyLocked" (click)="toggleEditMode(module, true, 'median')" [ngbTooltip]="'Edit median'" >
                  <con-icon-by-name iconName="pencil"></con-icon-by-name>
                </button>
              </ng-container>
            </div>
            <div class="errors" *ngIf="module?.formControls.median">
              <con-form-errors [control]="module?.formControls?.median" [extra]="module?.formErrors?.median"></con-form-errors>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-between align-items-center">
              <ng-container *ngIf="module?.formControls?.high">
                <input class="from-control form-control-sm" type="number" [formControl]="module?.formControls?.high">
                <button class="btn btn-sm btn-success mx-1" (click)="saveField(module, 'high')" [disabled]="module?.loaders?.high || module?.formControls?.high?.pristine">
                  <con-icon-by-name iconName="check" *ngIf="!module?.loaders?.high"></con-icon-by-name>
                  <div class="spinner-border spinner-border-sm" *ngIf="module?.loaders?.high"></div>
                </button>
                <button class="btn btn-sm btn-secondary"  (click)="toggleEditMode(module, false, 'high')"> <con-icon-by-name iconName="times"></con-icon-by-name></button>
              </ng-container>
              <ng-container *ngIf="!module?.formControls?.high">
                {{ !module?.high ? '-' : module?.high | imask: maskOptions }}
                <button class="btn btn-sm ml-1 px-0 no-shadow" *ngIf="!isSurveyLocked" (click)="toggleEditMode(module, true, 'high')" [ngbTooltip]="'Edit high'" >
                  <con-icon-by-name iconName="pencil"></con-icon-by-name>
                </button>
              </ng-container>
            </div>
            <div class="errors" *ngIf="module?.formControls.high">
              <con-form-errors [control]="module?.formControls?.high" [extra]="module?.formErrors?.high"></con-form-errors>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-between align-items-center">
              <ng-container *ngIf="module?.formControls?.low">
                <input class="from-control form-control-sm" type="number" [formControl]="module?.formControls?.low">
                <button class="btn btn-sm btn-success mx-1" (click)="saveField(module, 'low')" [disabled]="module?.loaders?.low || module?.formControls?.low?.pristine">
                  <con-icon-by-name iconName="check" *ngIf="!module?.loaders?.low"></con-icon-by-name>
                  <div class="spinner-border spinner-border-sm" *ngIf="module?.loaders?.low"></div>
                </button>
                <button class="btn btn-sm btn-secondary"  (click)="toggleEditMode(module, false, 'low')"> <con-icon-by-name iconName="times"></con-icon-by-name></button>
              </ng-container>
              <ng-container *ngIf="!module?.formControls?.low">
                {{ !module?.low ? '-' : module?.low | imask: maskOptions }}
                <button class="btn btn-sm ml-1 px-0 no-shadow" *ngIf="!isSurveyLocked" (click)="toggleEditMode(module, true, 'low')" [ngbTooltip]="'Edit low'" >
                  <con-icon-by-name iconName="pencil"></con-icon-by-name>
                </button>
              </ng-container>
            </div>
            <div class="errors" *ngIf="module?.formControls.low">
              <con-form-errors [control]="module?.formControls?.low" [extra]="module?.formErrors?.low"></con-form-errors>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-between align-items-center">
              <ng-container *ngIf="module?.formControls?.contributors_count">
                <input class="from-control form-control-sm" type="number" [formControl]="module?.formControls?.contributors_count">
                <button class="btn btn-sm btn-success mx-1" (click)="saveField(module, 'contributors_count')" [disabled]="module?.loaders?.contributors_count || module?.formControls?.contributors_count?.pristine">
                  <con-icon-by-name iconName="check" *ngIf="!module?.loaders?.contributors_count"></con-icon-by-name>
                  <div class="spinner-border spinner-border-sm" *ngIf="module?.loaders?.contributors_count"></div>
                </button>
                <button class="btn btn-sm btn-secondary"  (click)="toggleEditMode(module, false, 'contributors_count')"> <con-icon-by-name iconName="times"></con-icon-by-name></button>
              </ng-container>
              <ng-container *ngIf="!module?.formControls?.contributors_count">
                {{ module?.contributors_count || '-' }}
                <button class="btn btn-sm ml-1 px-0 no-shadow" *ngIf="!isSurveyLocked" (click)="toggleEditMode(module, true, 'contributors_count')">
                  <con-icon-by-name iconName="pencil"></con-icon-by-name>
                </button>
              </ng-container>
            </div>
            <div class="errors" *ngIf="module?.formControls.contributors_count">
              <con-form-errors [control]="module?.formControls?.contributors_count" [extra]="module?.formErrors?.contributors_count"></con-form-errors>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
